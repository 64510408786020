import toast from 'react-hot-toast';
import {
  requestPasswordReset,
  resetPassword,
  signIn,
  signUp,
} from './util/API';

export const model = {
  user: '',
  sessionToken: '',

  checkSession: function () {
    const sessionToken = sessionStorage.getItem('sessionToken');
    if (!sessionToken) {
      return;
    }
    this.sessionToken = sessionToken;
  },

  signUp: async function (email: string, password: string) {
    const response = await signUp(email, password);
    if (response.error) {
      toast.error(response.error);
      return;
    }
    this.user = response.user;
    this.sessionToken = response.sessionToken;
  },

  signIn: async function (email: string, password: string) {
    const response = await signIn(email, password);
    if (response.error) {
      toast.error(response.error);
      return;
    }
    this.user = response.user;
    this.sessionToken = response.sessionToken;
    sessionStorage.setItem('sessionToken', this.sessionToken);
  },

  requestPasswordReset: async function (email: string) {
    const response = await requestPasswordReset(email);
    if (response.error) {
      toast.error(response.error);
      return false;
    }
    toast.success(response.message);
    return true;
  },
  resetPassword: async function (code: string, password: string) {
    const response = await resetPassword(code, password);
    if (response.error) {
      toast.error(response.error);
      return false;
    }
    toast.success(response.message);
    return true;
  },
};

export type Model = typeof model;
