const endpoint =
  window.location.hostname === 'localhost'
    ? 'http://localhost:8080'
    : 'https://api.exomanager.com';

//Auth section
export const signUp = async (email: string, password: string) => {
  try {
    const response = await fetch(`${endpoint}/auth/signUp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    const data = await response.json();
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const signIn = async (email: string, password: string) => {
  try {
    const response = await fetch(`${endpoint}/auth/signIn`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    const data = await response.json();
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const requestPasswordReset = async (email: string) => {
  try {
    const response = await fetch(`${endpoint}/auth/requestPasswordReset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, redirect: window.location.origin }),
    });
    const data = await response.json();
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};

export const resetPassword = async (code: string, password: string) => {
  try {
    const response = await fetch(`${endpoint}/auth/resetPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code, password }),
    });
    const data = await response.json();
    return data;
  } catch (error: any) {
    return { error: error.message };
  }
};
