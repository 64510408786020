import { observer } from 'mobx-react-lite';
import './App.css';
import { Model } from './Model';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignIn from './components/signIn/SignIn';
import { Box } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SignUp from './components/signIn/SignUp';
import ResetPassword from './components/signIn/ResetPassword';
import { Toaster } from 'react-hot-toast';
import InputNewPassword from './components/signIn/InputNewPassword';
import { useEffect } from 'react';
interface Props {
  model: Model;
}

function App(props: Props) {
  const { model } = props;
  const theme = createTheme({
    palette: {
      mode: true ? 'dark' : 'light',
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#dc004e',
      },
    },
  });

  useEffect(() => {
    model.checkSession();
  }, [model]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: '100vw',
          overflow: 'hidden',
          justifyContent: 'center',
          height: '100dvh',
          display: 'flex',
          alignItems: 'center',
          color: 'text.primary',
        }}
      >
        <Toaster />
        <BrowserRouter>
          <Routes>
            {!model.sessionToken ? (
              <>
                <Route path="sign-up" element={<SignUp model={model} />} />
                <Route
                  path="reset-password"
                  element={<ResetPassword model={model} />}
                />
                <Route
                  path="reset-code/:id"
                  element={<InputNewPassword model={model} />}
                />
                <Route path="*" element={<SignIn model={model} />} />
              </>
            ) : (
              <Route path="/">
                <Route path="*" element={<>404 not found</>} />
              </Route>
            )}
          </Routes>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}

export default observer(App);
