import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { model } from './Model';
import { observable, configure } from 'mobx';

configure({ enforceActions: 'never' });
const observableModel = observable(model);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App model={observableModel} />
  </React.StrictMode>
);
