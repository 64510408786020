import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Container, Paper } from '@mui/material';
import { Model } from '../../Model';
import { observer } from 'mobx-react-lite';
import toast from 'react-hot-toast';
interface Props {
  model: Model;
}
const InputNewPassword = (props: Props) => {
  const { model } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!id) {
      toast.error('Invalid reset code');
      return;
    }
    const response = await model.resetPassword(id, e.target.password.value);
    if (response) {
      navigate('/sign-in');
    }
  };

  return (
    <Paper elevation={16}>
      <Container
        maxWidth="xs"
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Input new password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate={false}
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset password
          </Button>
          <Grid container>
            <Grid item xs>
              <RouterLink to="/sign-in">
                {'Know your password? Sign In'}
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Paper>
  );
};

export default observer(InputNewPassword);
