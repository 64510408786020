import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Link as RouterLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Container, Paper } from '@mui/material';
import { Model } from '../../Model';
import { observer } from 'mobx-react-lite';

interface Props {
  model: Model;
}

const SignUp = (props: Props) => {
  const { model } = props;
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    await model.signUp(e.target.email.value, e.target.password.value);
    console.log('sign up');
  };

  return (
    <Paper elevation={16}>
      <Container
        maxWidth="xs"
        sx={{
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignSelf: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item xs>
              <RouterLink to="/sign-in">
                {'Already have an account?'}
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Paper>
  );
};

export default observer(SignUp);
